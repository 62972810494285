@import url('https://fonts.googleapis.com/css2?family=Space+Mono:wght@400;700&display=swap');

:root{
  /*Colors*/
  --clrLightCyan:hsl(173,61%,77%);
  --clrStrongCyan: hsl(172, 67%, 45%);
  --clrMediumCyan:hsl(183,80%,24%);
  --clrVeryDarkCyan: hsl(183, 100%, 15%);
  --clrDarkGrayishCyan: hsl(186, 14%, 43%);
  --clrGrayishCyan: hsl(184, 14%, 56%);
  --clrLightGrayishCyan: hsl(185, 41%, 84%);
  --clrVeryLightGrayishCyan: hsl(189, 35%, 93%);
}

*,
*::after,
*::before{
  padding:0;
  margin: 0;
  box-sizing: border-box;
}

/*Utilities */
.btn{
 border: none;
 padding: 15px;
 color:var(--clrVeryDarkCyan);
 background: var(--clrMediumCyan);
 text-transform: uppercase;
 font-size: 18px;
 font-weight: 700;
 border-radius: 5px;
}

.btn.reset-active{
background: var(--clrStrongCyan);
cursor: pointer;
}

body{
  font-family: 'Space Mono', monospace;
  height: 100vh;
  background: var(--clrLightGrayishCyan);
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Space Mono', monospace;
  font-weight: 700;
}

header{
  margin-bottom: 50px;
}

.main-title{
  text-align: center;
  color:var(--clrVeryDarkCyan);
  font-size: 25px;
  letter-spacing: 10px;
  line-height: 1.5;
}

.main-panel{
  height:500px;
  width: 920px;
  background: #fff;
  border-radius: 30px;
  display:grid;
  grid-template-columns: repeat(2,1fr);
  gap:50px;
  padding: 40px;
}

.splitter-panel{
color:var(--clrDarkGrayishCyan);
}

.bill-input-group, 
.tip-calculator{/*Change the classname to tip-input-group*/
  margin-bottom: 40px;
  }
  
  .bill-title-group{
    display: flex;
    justify-content: space-between;
  }

  .tip-title{
    margin-bottom: 20px;
  }

  /*Bill input + Number of people input*/
 
  .percentage-grid{
    display: grid;
    grid-template-columns: repeat(3,1fr);
    gap:15px;
  }
  
 .input-field,
 .percentage
 {
  height: 50px;
  width: 100%;
  border:none;
  background: var(--clrVeryLightGrayishCyan);
  color:var(--clrVeryDarkCyan);
  padding: 5px 10px;
  border-radius: 5px;
  text-align: right;
  font-size: 25px;
  font-family:inherit;
  font-weight: 700;
}

.input-title{
  margin-bottom: 10px;
}

.percentage{
  text-align: center;
  color:var(--clrVeryLightGrayishCyan);
  background: var(--clrVeryDarkCyan);
  transition: all 0.1s ease-in-out;
}

.percentage.active{
  background: var(--clrStrongCyan);
  color:var(--clrVeryDarkCyan);
}

.percentage.custom{
  height: 50px;
  background: var(--clrVeryLightGrayishCyan);
  text-align: right;
  color:var(--clrVeryDarkCyan);
}

.percentage.custom::placeholder{
  color:var(--clrDarkGrayishCyan);
}


.percentage:not(.custom):hover {
  color:var(--clrVeryDarkCyan);
  background: var(--clrLightCyan);
}

input[type='text']::placeholder{
  color:var(--clrGrayishCyan);
}

input[type='text']:focus{
  outline: 2px solid var(--clrStrongCyan);
}

.input-field.alert{
  outline:2px solid red;

}

.alert{
  color:red;
}


label[for="bill-input"], label[for="nbr-people"]{
  position: relative;
}

label[for="bill-input"]::before, 
label[for="nbr-people"]::before {
  position: absolute;
  color:var(--clrGrayishCyan);
  font-size: 20px;
  font-weight: 400;
  font-family: 'Space Mono', monospace;
  top:50%;
  left:10px;
  transform: translateY(-50%);
}

label[for="bill-input"]::before{
  content:'$'
}

label[for="nbr-people"]::before{
  content: url('./images/icon-person.svg');
}

.nbr-people-container{
  display: flex;
  justify-content: space-between;
}

/*Total Panel*/
.total-panel{
  border-radius: 15px;
  background: var(--clrVeryDarkCyan);
  padding: 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.amount{
  display: flex;
  justify-content: space-between;
  align-items:center;
  margin-bottom: 30px;
}

.amount h3{
  font-size: 15px;
  margin-bottom: 5px;
}

.amount p{
  font-size: 13px;
  color:var(--clrGrayishCyan);
}

.amount-label{
  color:var(--clrVeryLightGrayishCyan);
}

.amount h1{
  font-size: 50px;
  color:var(--clrStrongCyan)
}

